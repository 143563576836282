import React, { useEffect, useState } from "react"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../components/font-awsome"
import { graphql, Link } from "gatsby"
import { iconWeb } from '../components/icon-web'
import useInterval from "@use-it/interval"
import Layout from "~/components/layout"
import Seo from "~/components/seo"
import styled from "styled-components"
import CarouselComponent from '../components/carousel';
import CarouselBannerComponent from '../components/carousel-banner';
import CarouselVerticalComponent from '../components/carousel-vertical';
import CarouselHorizon from '../components/carousel-horizon';
import FeatureComponent from '../components/feature';
import GameCategoryComponent from '../components/gameCategory';
import CarouselButton from '../components/carousel-button';
import CarouselVerticalMobileComponent from '../components/carousel-vertical-mobile';
import CarouselNewsMobile from '../components/carousel-news-mobile';
import hotIcon from "~/images/icon_fire.png"
import newIcon from "~/images/icon_new.png"
import featureIcon from "~/images/icon_feature.png"
import jwt from 'jsonwebtoken';
import {
  DEMO_URL,
} from "gatsby-env-variables"
import PlayUrlContext from "~/context/play-url"

const MainContent = styled.div`
  width: 100%;
  max-width: 1280px;
  padding: 20px;

  .grid {
    display: grid;
    grid-template-columns: 75% 25%;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const MainContentMobile = styled.div`
  width: 100%;

  div {
    color: var(--font-primary);
  }

  .banner {
    position: relative;
    height: 255px;
  }

  .linkMenu {
    text-decoration: underline;
    cursor: pointer;
    color: var(--font-golden);
  }

  @media screen and (min-width: 769px) {
    display: none;
  }
`

const ButtonFloat = styled.div`
  position: absolute;
  width: 90%;
  padding: 10px;
  background: var(--bg-primary);
  border-radius: 16px;
  margin-left: auto;
  margin-right: auto;
  top: 30%;
  left: 0;
  right: 0;
  text-align: center;
  color: var(--font-primary);
  box-shadow: 0px 3px 6px #FFD7D530;
  border-radius: 16px;
  opacity: 1;

  div {
    color: var(--font-primary);
  }

  .detail-game {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 10px;
    padding: 5px;
    margin-top: 10px;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 8px;
      padding: 2px;
      background: linear-gradient(45deg, var(--color-primary), var(--color-secondary));
      mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
              mask-composite: exclude;
      pointer-events: none;
    }
  }

  .card-image {
    max-height: 60px;
    aspect-ratio: 1 / 1;
  }

  .tag-game {
    display: flex;
    justify-content: start;
  }
`

const PlayButton = styled.div`
  padding: 10px;
  color: var(--font-primary);
  line-height: 1;
  cursor: pointer;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 8px;
    padding: 2px;
    background: linear-gradient(45deg, var(--color-primary), var(--color-secondary));
    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
    pointer-events: none;
  }
`

const IconFeat = styled.div`
  background: var(--color-fourth);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 47px;
  height: 47px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;

  &.-active {
    background: var(--color-secondary);
  }
`

const Icon = styled.div`
  width: 24px;
  height: 24px;
  background: var(--icon-support-color);
  cursor: pointer;
  mask: url(${props => props.icon}) no-repeat center / contain;
  -webkit-mask: url(${props => props.icon}) no-repeat center / contain;
`

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
    markdownRemark: {
      fields: { langKey },
      frontmatter
    },
    trending,
    promotion,
    event,
    feature,
    common,
    word
  },
  pageContext,
}) => {
  const { favicon_logo } = common.edges
    .find(item => item.node.file
      .split(".")[1]
      .replace("/", "") === langKey).node.frontmatter
  const _word = word.edges
    .find(item => item.node.file
      .split(".")[1]
      .replace("/", "") === langKey).node
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);
  const [currentCate, setCurrentCate] = useState("allgames");
  const [listFeat, setListFeat] = useState([]);
  const [renderData, setRenderData] = useState([])
  const [renderProv, setRenderProv] = useState([])
  const [categories, setCategories] = useState([])
  const [listProv, setListProv] = useState([]);
  const [currentMenu, setCurrentMenu] = useState("news");
  const [currentProv, setCurrentProv] = useState();
  const [newGame, setNewGame] = useState([]);
  const { updateCategories, gameData, themeLanding, webConfig } = React.useContext(PlayUrlContext);
  const bannerList = edges
    .filter(item => item.node.file.split("/")[1] === langKey)
    .map(item => item.node)
  const trendingList = trending.edges
    .filter(item => item.node.file.split("/")[1] === langKey)
    .map(item => item.node)
  const promotionList = promotion.edges
    .filter(item => item.node.file.split("/")[1] === langKey)
    .map(item => item.node)
  const eventList = event.edges
    .filter(item => item.node.file.split("/")[1] === langKey)
    .map(item => item.node)
  const featureList = feature.edges
    .filter(item => item.node.file.split("/")[1] === langKey)
    .map(item => item.node)

  useEffect(() => {
    const fetchData = () => {
      setData(gameData)
      setRenderData(gameData)
      setRenderProv(gameData)
    }
    fetchData();
  }, [gameData])

  useEffect(() => {
    const type = data.map(item => item.type)
    const partner = data.map(item => item.partner)
    const mapCate = type
      .filter((v, i) => type.indexOf(v) === i)
      .map(item => ({
        text: item,
        icon: item,
      }))
    const partnerList = partner.filter((v, i) => partner.indexOf(v) === i)
      .map(item => item.charAt(0).toUpperCase() + item.slice(1, item.length).toLowerCase())
    setListProv(partnerList.map(items => ({
      text: items,
      icon: items,
    })))
    const feature = data
      .filter(item => item.tag.length && !item.tag.every(t => t === ""))
      .map(item => item.tag)
    const mergedFeature = [].concat.apply([], feature)
    const featureLists = mergedFeature.filter(
      (v, i) => mergedFeature.indexOf(v) === i
    ).map(item => item.charAt(0).toUpperCase() + item.slice(1, item.length).toLowerCase())
    setListFeat(featureLists)
    setCurrentProv(partnerList[0])
    setCategories([
      {
        text: "All Games",
        icon: "allgames",
      },
      ...mapCate,
    ])
    updateCategories([
      {
        text: "All Games",
        icon: "allgames",
      },
      ...mapCate,
    ])
  }, [data])

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  useInterval(() => {
    setCurrentSlideIndex(
      bannerList.length - 1 === currentSlideIndex ? 0 : currentSlideIndex + 1
    )
  }, 4000)

  const handleCate = cate => {
    setCurrentCate(cate)
    const newRenderData = data.filter(item => {
      if (currentCate === "allgames") return item
      else return item.type === currentCate
    })
    setRenderData(newRenderData)
  }

  const handleMenu = value => {
    setCurrentMenu(value)
  }

  const handleProv = value => {
    setCurrentProv(value)
    const newRenderProv = data.filter(item => {
      return item.partner === currentProv.toUpperCase()
    })
    setRenderProv(newRenderProv)
  }

  useEffect(() => {
    const res = renderData.filter(item => {
      return item.kind.toLowerCase() === 'new'
    })
    setNewGame(res[0])
  }, [renderData])

  const openGames = (value) => {
    const checkMobile =
      /Android|iPod|iPad|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    if (!value.status && value.demoUrl) {
      const playStaging = jwt.sign({
        link: value.demoUrl + `?lang=${langKey}`,
        provider: value.partner,
        gameName: value.name,
        mode: !checkMobile ? 'desktop' : 'mobile',
        lang: langKey,
        backLink: '',
        gameType: value.type,
        prefix: value.prefix,
      }, 'wrong-secret')
      const Features = 'top=0,left=0,height=768,width=1366,scrollbars=no'
      window.open(`${DEMO_URL}?link=${playStaging}`, Features, 'popup')
    }
  }

  return (
    <Layout pageContext={pageContext}>
      <ToastContainer />
      <Seo
        title={frontmatter.metadata.metaTitle}
        description={frontmatter.metadata.metaDescription}
        favicon_logo={`${webConfig && webConfig.favicon ? webConfig.favicon : favicon_logo}`}
      />
      <MainContent>
        <div className='grid'>
          <CarouselComponent list={data} headerText={_word.frontmatter.trending_now} />
          <CarouselVerticalComponent list={data} headerText={_word.frontmatter.promotion} />
        </div>
        <div className='mt-10'>
          <CarouselBannerComponent list={data} langKey={langKey} headerText={_word.frontmatter.new_game} />
        </div>
        <div className='mt-10'>
          <CarouselVerticalComponent list={data} langKey={langKey} cate={'HOT'} headerText={_word.frontmatter.hot_game} />
        </div>
        <div className='mt-10'>
          <CarouselHorizon list={eventList} headerText={_word.frontmatter.news} />
        </div>
        <div className='mt-10'>
          <GameCategoryComponent list={data} categories={categories} headerText={_word.frontmatter.game_category} />
        </div>
        <div className='mt-10'>
          <CarouselVerticalComponent list={data} headerText={_word.frontmatter.recommend_game} langKey={langKey} cate={'RECOMMEND'} />
        </div>
        <div className='mt-10'>
          <FeatureComponent list={featureList} headerText={_word.frontmatter.feature} />
        </div>
      </MainContent>
      <MainContentMobile>
        <div className='banner'>
          {bannerList && bannerList.map((items, index) => (
            <div key={index}
              style={{
                display: currentSlideIndex === index ? "block" : "none",
              }}
              className="fade"
            >
              <img src={`/${items.frontmatter.image}`} alt="" />
            </div>
          ))}
          <ButtonFloat>
            <div className='flex justify-between w-full text-center'>
              {listFeat.map((items, index) => (
                <Link
                  key={index}
                  to={langKey === 'en' ? `/game/?sort=${items}` : `/${langKey}/game/?sort=${items}`}>
                  <div className='inline-block'>
                    <IconFeat>
                      <img
                        src={items === 'Hot' ? iconWeb[themeLanding].hot
                          : items === 'Hit' ? iconWeb[themeLanding].hit
                            : items === 'Recommend' ? iconWeb[themeLanding].recommend
                              : iconWeb[themeLanding].new}
                        style={{ width: '23px', height: '28px' }}
                        alt='icon'
                      />
                    </IconFeat>
                    {_word.frontmatter[items.toLowerCase()]}
                  </div>
                </Link>
              ))}
            </div>
            <div className='detail-game'>
              <div className='card-image'>
                <img src={newGame ? newGame.image?.portraitImage : ''} alt='' />
              </div>
              <div className='game-name'>
                <div>{newGame?.name ? newGame.name[langKey] : ''}</div>
                <div className='tag-game'>
                  {newGame && newGame.tag?.map((items, index) => (
                    <img key={index} src={items === 'HOT' ? hotIcon
                      : items === 'NEW' ? newIcon : featureIcon
                    }
                      style={{
                        backgroundColor:
                          items === "NEW"
                            ? "#13C750"
                            : items === "HOT"
                              ? "#FC4C60"
                              : "#7639FF",
                        color: "#fff",
                        backgroundImage:
                          items === "NEW"
                            ? `url(${newIcon})`
                            : items === "HOT"
                              ? `url(${hotIcon})`
                              : `url(${featureIcon})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "3px center",
                        padding: "5px",
                        margin: "5px",
                        borderRadius: "5px"
                      }}
                      alt=''
                    />
                  ))}
                </div>
              </div>
              <PlayButton onClick={() => openGames(newGame)}>{_word.frontmatter.try_now}</PlayButton>
            </div>
          </ButtonFloat>
        </div>
        <div>
          <div className='flex items-center justify-between p-3 mt-5'>
            <div className='headText'>{_word.frontmatter.all_game}</div>
            <Link to={langKey === 'en' ? '/game' : `/${langKey}/game`}>
              <div className='linkMenu'>{_word.frontmatter.see_all}</div>
            </Link>
          </div>
          <CarouselButton categories={categories} handleCate={handleCate} currentCate={currentCate} />
          <CarouselVerticalMobileComponent list={renderData} langKey={langKey} />
        </div>
        <div>
          <CarouselButton
            categories={[
              { text: `${_word.frontmatter.news}`, icon: "news" },
              { text: _word.frontmatter.promotion, icon: "promotion" }
            ]}
            handleCate={handleMenu}
            currentCate={currentMenu}
          />
          <CarouselNewsMobile list={eventList.filter(items => items.frontmatter.type.toLowerCase() === currentMenu.toLowerCase())} langKey={langKey} />
        </div>
        <div>
          <CarouselButton
            categories={listProv}
            handleCate={handleProv}
            currentCate={currentProv}
          />
          <CarouselVerticalMobileComponent list={renderProv} langKey={langKey} />
        </div>
        <div>
          <FeatureComponent list={featureList} headerText={_word.frontmatter.feature} />
        </div>
      </MainContentMobile>
    </Layout>
  )
}

export const query = graphql`
  query IndexPageTemplate($langKey: String!) {
    allMarkdownRemark(
      filter: {
        collection: {eq: "banner"}
      }
      sort: {order: ASC, fields: frontmatter___order}
    ) {
      edges {
        node {
          file
          frontmatter {
            image
            dot_thumbnail
            banner_text
            button_text
          }
        }
      }
    }

    markdownRemark(
       frontmatter: {templateKey: {eq: "index"}}
       fields: {langKey: {eq: $langKey}}
     ) {
       id
       frontmatter {
         metadata {
           metaDescription
           metaTitle
         }
         filter_toggle
         filter_show
         show_more
         filters
         sort
         signin
         login
         cancel
         username
         password
         search_label
         search_button
         try_now
         enable_menu
       }
       fields {
         langKey
       }
     }

    event: allMarkdownRemark(
      filter: {
        collection: { eq: "event" }
        frontmatter: { is_active: { eq: true } }
      }
      sort: { fields: frontmatter___start_date, order: DESC }
    ) {
      edges {
        node {
          file
          html
          frontmatter {
            type
            title
            start_date(formatString: "DD MMMM YYYY")
            end_date(formatString: "DD MMMM YYYY")
            thumbnail
          }
        }
      }
    }

    trending: allMarkdownRemark(
      filter: {
        collection: {eq: "trending"}
      }
      sort: {order: ASC, fields: frontmatter___order}
    ) {
      edges {
        node {
          file
          frontmatter {
            image
            Icon
            banner_text
            button_text
          }
        }
      }
    }

    promotion: allMarkdownRemark(
      filter: {
        collection: {eq: "promotion"}
      }
      sort: {order: ASC, fields: frontmatter___order}
    ) {
      edges {
        node {
          file
          frontmatter {
            name
            image
          }
        }
      }
    }

    feature: allMarkdownRemark(
    filter: {collection: {eq: "feature"}, frontmatter: {is_active: {eq: true}}}
    sort: {fields: frontmatter___order, order: ASC}
    ) {
      edges {
        node {
          file
          html
          frontmatter {
            title
            thumbnail
            image
          }
        }
      }
    }

    common: allMarkdownRemark(filter: {collection: {eq: "common"}}) {
      edges {
        node {
          file
          frontmatter {
            favicon_logo
          }
        }
      }
    }

    word: allMarkdownRemark(filter: {collection: {eq: "word"}}) {
      edges {
        node {
          file
          frontmatter {
            search
            play
            home
            game
            news
            download
            trending_now
            promotion
            new_game
            hot_game
            game_category
            slot
            card
            arcade
            fishing
            board
            recommend_game
            featured
            chat
            event
            game_release
            sort_by
            hot
            hit
            recommend
            new
            show_more
            play_now
            try_now
            all_game
            see_all
          }
        }
      }
    }
  }
`

export default IndexPage
