import React from "react"
import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import ButtonCard from './button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import jwt from 'jsonwebtoken';
import hotIcon from "~/images/icon_fire.png"
import newIcon from "~/images/icon_new.png"
import featureIcon from "~/images/icon_feature.png"

const WrapperCarousel = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`

const WrapperCard = styled.div`
  height: 200px;
  display: block;
  margin-top: 10px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  .boxImage {
    min-height: 112px;
    position: relative;
  }

  &:hover .glassImg {
    visibility: visible;
    opacity:1;
    padding: 25px 40px;
  }

  .glassImg {
    visibility: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    z-index: 20;
    opacity:0;
    transition: visibility 0.3s linear, opacity 0.3s linear;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 40px;
  }

  .content {
    background: var(--bg-secondary);
    box-shadow: 0px 3px 6px #00000029;
    padding: 10px;
    color: var(--font-primary);
    height: 100%;

    p {
      color: var(--font-primary);
    }
  }

  .logo {
    position: absolute;
    width: 100px;
    top: 0;
    left: 0;
  }
`

const TagWrapper = styled.div`
  font-size: 10px;
  width: 150px;
  margin: auto;

  div {
    padding: 2px 5px 2px 15px;
    margin: 3px;

    & + div {
      margin-top: 5px;
    }
  }
`

export default function CarouselBannerComponent({ list, headerText, langKey }) {
  const settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1208,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
    ]
  };

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button onClick={onClick}>
        <div
          className={className}
          style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
      </button>
    );
  };

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button onClick={onClick}>
        <div
          className={className}
          style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </button>
    );
  };

  const handleEvent = (value) => {
    const checkMobile =
    /Android|iPod|iPad|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
    if (value.demoUrl) {
      const playStaging = jwt.sign({
        link: value.demoUrl + `?lang=${langKey}`,
        provider: value.partner,
        gameName: value.name,
        mode: !checkMobile ? 'desktop' : 'mobile',
        lang: langKey,
        backLink: '',
        gameType: value.type,
        prefix: value.prefix,
      }, 'wrong-secret')
      const Features = 'top=0,left=0,height=768,width=1366,scrollbars=no'
      window.open(`${value.demoUrl}?link=${playStaging}`, Features, 'popup')
    }
  };

  return (
    <WrapperCarousel>
      <div className='headText'>{headerText}</div>
      <Slider {...settings}>
        {list.filter(v => v.kind.toLowerCase() === 'new').map((items, index) => (
          <div className='relative px-1' key={index}>
            <WrapperCard>
              <div className='boxImage'>
                <img src={items.image.landscapeImage} alt='' />
                <div className='logo'>
                  <img src={items.image.logoImage} alt='' />
                </div>
                <div className='glassImg'>
                  <ButtonCard text={'Try Now'} onPress={() => handleEvent(items)}/>
                </div>
              </div>
              <div className='content'>
                <h4>{items.name[langKey]}</h4>
                <div className=''>
                  <h1 className='text-sm font-bold'>
                    | rtp: {items.rtp} | x22500 Max
                  </h1>
                </div>
                <TagWrapper className={`bottom-0 cursor-pointer justify-center flex`}>
                    {items.tag.map((item, idx) => (
                      <div
                        key={idx}
                        className="table rounded"
                        style={{
                          backgroundColor:
                            item === "NEW"
                              ? "#13C750"
                              : item === "HOT"
                                ? "#FC4C60"
                                : "#7639FF",
                          color: "#fff",
                          backgroundImage:
                            item === "NEW"
                              ? `url(${newIcon})`
                              : item === "HOT"
                                ? `url(${hotIcon})`
                                : `url(${featureIcon})`,
                          backgroundSize: "10px",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "3px center",
                        }}
                      >
                        {item}
                      </div>
                    ))}
                  </TagWrapper>
              </div>
            </WrapperCard>
          </div>
        ))}
      </Slider>
    </WrapperCarousel>
  )
}