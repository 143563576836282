import React from "react"
import styled from 'styled-components'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import hotIcon from "~/images/icon_fire.png"
import newIcon from "~/images/icon_new.png"
import featureIcon from "~/images/icon_feature.png"

const WrapperCarousel = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;

  .headText {
    font-weight: bold;
    font-size: 26px;

    &::before {
      content: "";
      width: 4px;
      height: 4px;
      border: 4px #13C750 solid;
      box-shadow: 0px 3px 6px #00000029;
      opacity: 1;
      margin-right: 20px;
    }
  }
`

const WrapperCard = styled.div`
  display: block;
  margin-top: 10px;
  background: var(--bg-primary) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  height: 100%;
`

const TagWrapper = styled.div`
  font-size: 10px;
  margin: auto;
  position: absolute;
  background: transparent linear-gradient(180deg, var(--bg-card-primary) 0%, var(--bg-card-secondary) 53%, var(--bg-card-third) 100%) 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 8px 8px;
  opacity: 1;

  div {
    padding: 2px 5px 2px 15px;
    margin: 3px;
    font-size: 1rem;

    & + div {
      margin-top: 5px;
      font-size: 0.8rem;
    }
  }
`

export default function CarouselVerticalMobileComponent({ list, langKey }) {
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 4,
    variableWidth: true,
    gap: 10,
  };
  return (
    <WrapperCarousel>
      <Slider {...settings}>
        {list.map((items, index) => (
          <div className='relative px-1' key={index}>
            <WrapperCard key={index}>
              <img src={items.image.portraitImage} alt='' />
              <TagWrapper className={`bottom-0 cursor-pointer items-center flex-wrap justify-center ${items.tag[0] ? "flex" : "hidden"}`}>
                <div className='w-full text-center'>{items.name[langKey]}</div>
                {items.tag.map((item, idx) => (
                  <div
                    key={idx}
                    className="table rounded"
                    style={{
                      backgroundColor:
                        item === "NEW"
                          ? "#13C750"
                          : item === "HOT"
                            ? "#FC4C60"
                            : "#7639FF",
                      color: "#fff",
                      backgroundImage:
                        item === "NEW"
                          ? `url(${newIcon})`
                          : item === "HOT"
                            ? `url(${hotIcon})`
                            : `url(${featureIcon})`,
                      backgroundSize: "10px",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "3px center",
                    }}
                  >
                    {item}
                  </div>
                ))}
              </TagWrapper>
            </WrapperCard>
          </div>
        ))}
      </Slider>
    </WrapperCarousel>
  )
}